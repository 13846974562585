// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background: #343a40;
  color: white;
  text-align: center;
  display : flex;
  // padding: 10px;
  justify-content : space-between;
  align-items: center;
`;
const GetInvited = styled.div`
  text-align: center;
  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: #495057;
      color: #f8f9fa;
    }
  }`;

const Footer = () => {
  return (
    <FooterContainer>
      <p className="offices-text">Offices : Montreal , Canada - Dubai , UAE - Athens, Greece - Ukraine , Kiev - Valencia , Spain - Shenzhen , China. </p>
      <p className="footer-text">&copy; 2024 mesaverse.ai - All Rights Reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
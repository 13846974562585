import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import logo from '../assets/img/logo-X-Shield-transparent.png';
import '../App.css';

const CustomNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  return (
    <>
      <Navbar
        expand={false}
        className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
        sticky="top"
      >
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src={logo}
              width="100"
              height="100"
              className="d-inline-block align-top logo"
              alt="SxS Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={toggleOffcanvas}
          />
        </Container>
      </Navbar>

      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement="end"
        className="custom-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Mesaverse Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="nav-links">
            <Nav.Link
              href="https://sxs.com"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-link legacy-link"
              onClick={toggleOffcanvas} // Close the off-canvas after clicking
            >
              SxS - Analytical Success
            </Nav.Link>
            <Nav.Link
              href="https://n2l.com"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-link work-link"
              onClick={toggleOffcanvas}
            >
              N2L - Never Too Late
            </Nav.Link>
            <Nav.Link
              href="https://mp3.net"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-link play-link"
              onClick={toggleOffcanvas}
            >
              Mp3 - Militant Play
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomNavbar;

import React , {useEffect} from 'react';
import { BrowserRouter as Router , Route , Routes , useLocation } from 'react-router-dom';
import HomePage from './components/Main';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  } , [location]);

  return null;
};

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  )
}

export default App;

import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const LoginComponent = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center login-container">
      <Row>
        <Col>
          <div className="p-4" style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <h2 className="text-center mb-4">Mesaverse Citizen? Sign in below</h2>
            <Form>
              <Form.Group controlId="formUsername">
                <Form.Control type="text" placeholder="Username" />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mt-3">
                <Form.Control type="password" placeholder="Password"/>
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-4" style={{ backgroundColor: '#8b4513', borderColor: '#8b4513' }}>
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginComponent;
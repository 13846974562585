import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import shieldImg from '../assets/img/shield-sxs-logo.png';
import LoginComponent from './LoginComponent';

const ShieldComponent = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center vh-100'>
      <img src={shieldImg} alt="Shield" className='img-fluid shield-image' />

      <div className="container mt-4 shield-container">
        <div className="row">
          {/* Text Box */}
          <div className="col-lg-6 text-box p-4 rounded shadow mb-4 mb-lg-0">
            <p className="text-center mb-4">
              The Mesaverse represents an innovative idea designed to establish a worldwide community where the distinctions 
              between work, leisure, and legacy blend seamlessly. This initiative is supported by two unique ecosystems, N2L 
              and Mp3, which promote professional development, creative expression, and social influence, respectively. 
              Concurrently, our Global Charity SxS monitors these ecosystems, collecting a 10% royalty indefinitely.
            </p>

            {/* Button */}
            <div className="d-flex justify-content-center">
              <a href="https://sxs.com" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-primary custom-button">Begin Journey</button>
              </a>
            </div>
            {/* <div className="d-flex justify-content-center">
              <a href="https://sxs.com" target='_blank' rel="noopener noreferrer">
                <button className='btn btn-primary custom-button-2'>Learn more</button>
              </a>
            </div> */}
          </div>

          {/* Login Component */}
          <div className="col-lg-6">
            <LoginComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShieldComponent;

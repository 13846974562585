//Main.js

import React from 'react';
import CustomNavbar from './Navbar';
import NewsTicker from './NewsTicker';
import ShieldComponent from './ShieldComponent';
import Footer from './Footer';
export default function Main(){
    return (
        <div className='main-container'>
            <CustomNavbar />
            <NewsTicker />
            <ShieldComponent />
            <Footer />
        </div>
    )
}